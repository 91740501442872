<template>
  <div>
    <p>Авторизация по JWT токену</p>
  </div>
</template>

<script>
import api from "@/core/api";
import { auth } from "@/core/services/auth";

export default {
  props: ["id"],
  mounted() {
    api.getToken(this.id).then((resp) => {
      auth.setToken(resp.data.access_token);
      let profileUrl = this.$router.resolve({ name: "profile" });
      window.location.href = profileUrl.href;
    });
  },
};
</script>
